import React, { useState } from "react"
import { Modal, Row, Col } from "antd"

declare const window: Window &
  typeof globalThis & {
    onYouTubeIframeReady: any
  }

export const FeaturedVideo = ({ details }) => {
  const [show, setShow] = useState<boolean>(false)
  const [nwplayer, setPlayer] = useState<any>()

  if (typeof window !== "undefined") {
    var tag = document.createElement("script")
    //tag.src = "https://www.youtube.com/iframe_api";
    tag.src =
      "https://s.ytimg.com/yts/jsbin/www-widgetapi-vflioRwVn/www-widgetapi.js"
    tag.setAttribute("onload", "onYouTubeIframeReady()")
    var firstScriptTag = document.getElementsByTagName("script")[0]
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)

    var player

    window.onYouTubeIframeReady = function () {
      player = new window["YT"].Player(`video-${details[0].id}`, {
        videoId: details[0].link.substring(
          details[0].link.lastIndexOf("/") + 1
        ),
        playerVars: {
          playsinline: 1,
        },
        events: {
          onReady: onPlayerReady,
        },
      })
    }
  }

  function onPlayerReady(event) {
    event.target.playVideo()
    setPlayer(event)
  }

  const stopVideo = () => {
    nwplayer.target.stopVideo()
    setShow(false)
  }

  const play = () => {
    setShow(true)
  }

  return (
    <div className="section-featuredvideo">
      <img
        src={details[0]?.photo_thumbnail_url || details?.photo_url}
        alt="Thumbnail Featured Video"
      />
      <div className="play-btn-video">
        <a className="rhr-button" onClick={() => play()}>
          PLAY VIDEO
        </a>
      </div>
      <Modal
        centered
        visible={show}
        onCancel={stopVideo}
        footer={null}
        className="featured-video-modal"
        width={800}
      >
        <div id={`video-${details[0].id}`}></div>
      </Modal>
    </div>
  )
}

export const LeftSide = ({ details }) => {
  return (
    <div className="section-leftside">
      <Row>
        <Col xs={25} xl={12} className="left-container">
          <img
            src={details[0]?.photo_thumbnail_url || details?.photo_url}
            alt="Left Side Thumbnail"
          />
        </Col>
        <Col xl={12} xs={25} className="right-container">
          <div className="wrap">
            <h2
              className="section-title"
              dangerouslySetInnerHTML={{
                __html: details[0]?.header_title || details?.title,
              }}
            ></h2>
            <div className="actions-btn-section">
              <article
                dangerouslySetInnerHTML={{
                  __html:
                    details[0]?.text_content || details?.short_description,
                }}
              ></article>
              {details[0]?.link !== "" && (
                <a
                  href={details[0]?.link || details?.redirect_link}
                  className="rhr-button"
                  target="_blank"
                >
                  KNOW MORE
                </a>
              )}
              {details[0]?.pdf_url !== "" && details.pdf_url !== "" ? (
                <a
                  href={details[0]?.pdf_url || details?.pdf_url}
                  className="rhr-button white"
                  target="_blank"
                >
                  DOWNLOAD PDF
                </a>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const RightSide = ({ details }) => {
  return (
    <div className="section-rightside">
      <Row>
        <Col xl={12} xs={25} className="left-container">
          <div className="wrap">
            <h2
              className="section-title"
              dangerouslySetInnerHTML={{
                __html: details[0]?.header_title || details?.title,
              }}
            ></h2>
            <article
              dangerouslySetInnerHTML={{
                __html: details[0]?.text_content || details?.short_description,
              }}
            ></article>
            <div className="actions-btn-section">
              {details[0]?.link !== "" && (
                <a
                  href={details[0]?.link || details?.redirect_link}
                  className="rhr-button white"
                  target="_blank"
                >
                  KNOW MORE
                </a>
              )}
              {details[0]?.pdf_url !== "" && details.pdf_url !== "" ? (
                <a
                  href={details[0]?.pdf_url || details?.pdf_url}
                  className="rhr-button"
                  target="_blank"
                >
                  DOWNLOAD PDF
                </a>
              ) : null}
            </div>
          </div>
        </Col>
        <Col xl={12} className="right-container">
          <img
            src={details[0]?.photo_thumbnail_url || details?.photo_url}
            alt="Right Side Thumbnail"
          />
        </Col>
      </Row>
    </div>
  )
}

export const CenterTop = ({ details }) => {
  return (
    <div className="section-centertop text-center">
      <Row>
        <Col span={24}>
          <h2
            className="section-title"
            dangerouslySetInnerHTML={{ __html: details[0].header_title }}
          ></h2>
          <article
            dangerouslySetInnerHTML={{ __html: details[0].text_content }}
          ></article>
          <div className="actions-btn-section">
            {details[0].link !== "" && (
              <a href={details[0].link} className="rhr-button" target="_blank">
                KNOW MORE
              </a>
            )}
            {details[0].pdf_url !== "" && (
              <a
                href={details[0].pdf_url}
                className="rhr-button white"
                target="_blank"
              >
                DOWNLOAD PDF
              </a>
            )}
          </div>
        </Col>
        <Col span={24} className="right-container">
          <img
            src={details[0].photo_thumbnail_url}
            alt="Center Top Thumbnail"
          />
        </Col>
      </Row>
    </div>
  )
}

export const CenterBottom = ({ details }) => {
  return (
    <div className="section-centerbottom text-center">
      <Row>
        <Col span={24}>
          <h2
            className="section-title"
            dangerouslySetInnerHTML={{ __html: details[0].header_title }}
          ></h2>
        </Col>
        <Col span={24} className="right-container">
          <img
            src={details[0].photo_thumbnail_url}
            alt="Center Top Thumbnail"
          />
        </Col>
        <Col span={24}>
          <article
            dangerouslySetInnerHTML={{ __html: details[0].text_content }}
          ></article>
          <div className="actions-btn-section">
            {details[0].link !== "" && (
              <a href={details[0].link} className="rhr-button" target="_blank">
                KNOW MORE
              </a>
            )}
            {details[0].pdf_url !== "" && (
              <a
                href={details[0].pdf_url}
                className="rhr-button white"
                target="_blank"
              >
                DOWNLOAD PDF
              </a>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const RightAlign = ({ details }) => {
  return (
    <div className="section-rightalign text-right">
      <Row>
        <Col span={24}>
          <div className="wrap">
            <h2
              className="section-title"
              dangerouslySetInnerHTML={{ __html: details[0].header_title }}
            ></h2>
            <div className="actions-btn-section">
              <article
                dangerouslySetInnerHTML={{ __html: details[0].text_content }}
              ></article>
              {details[0].link !== "" && (
                <a
                  href={details[0].link}
                  className="rhr-button"
                  target="_blank"
                >
                  KNOW MORE
                </a>
              )}
              {details[0].pdf_url !== "" && (
                <a
                  href={details[0].pdf_url}
                  className="rhr-button white"
                  target="_blank"
                >
                  DOWNLOAD PDF
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const LeftAlign = ({ details }) => {
  //console.log(details);

  return (
    <div className="section-leftalign">
      <Row>
        <Col span={24}>
          <div className="wrap">
            <h2
              className="section-title"
              dangerouslySetInnerHTML={{ __html: details[0].header_title }}
            ></h2>
            <div className="actions-btn-section">
              <article
                dangerouslySetInnerHTML={{ __html: details[0].text_content }}
              ></article>
              {details[0].link !== "" && (
                <a
                  href={details[0].link}
                  className="rhr-button"
                  target="_blank"
                >
                  KNOW MORE
                </a>
              )}
              {details[0].pdf_url !== "" && (
                <a
                  href={details[0].pdf_url}
                  className="rhr-button white"
                  target="_blank"
                >
                  DOWNLOAD PDF
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const CenterALign = ({ details }) => {
  return (
    <div className="section-centeralign text-center">
      <Row>
        <Col span={24}>
          <div className="wrap">
            <h2
              className="section-title"
              dangerouslySetInnerHTML={{
                __html: details[0]?.header_title || details?.title,
              }}
            ></h2>
            <div className="actions-btn-section">
              <article
                dangerouslySetInnerHTML={{
                  __html:
                    details[0]?.text_content || details?.short_description,
                }}
              ></article>
              {details[0]?.link !== "" && (
                <a
                  href={details[0]?.link || details?.redirect_link}
                  className="rhr-button"
                  target="_blank"
                >
                  KNOW MORE
                </a>
              )}
              {details[0]?.pdf_url !== "" && details.pdf_url !== "" ? (
                <a
                  href={details[0]?.pdf_url || details?.pdf_url}
                  className="rhr-button white"
                  target="_blank"
                >
                  DOWNLOAD PDF
                </a>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
